const LGAs = [
    "Auyo", "Babura", "Biriniwa", "Birnin Kudu", "Buji", "Dutse", "Gagarawa", "Garki", "Gumel", "Guri",
    "Gwaram", "Gwiwa", "Hadejia", "Jahun", "Kafin Hausa", "Kaugama", "Kazaure", "Kiri Kasama", "Kiyawa",
    "Maigatari", "Malam Madori", "Miga", "Ringim", "Roni", "Sule Tankarkar", "Taura", "Yankwashi"
  ];
  
  const projectTypes = ["Road", "School", "Hospital", "Water Supply", "Electrification"];
  const projectStatuses = ["Completed", "Ongoing", "Abandoned", "Planned"];
  
  const generateRandomProject = (id) => {
    const lga = LGAs[Math.floor(Math.random() * LGAs.length)];
    const type = projectTypes[Math.floor(Math.random() * projectTypes.length)];
    const status = projectStatuses[Math.floor(Math.random() * projectStatuses.length)];
    
    return {
      id,
      name: `${type} Project in ${lga}`,
      lga,
      type,
      status,
      budget: Math.floor(Math.random() * 1000000000) + 100000000, // 100M to 1.1B Naira
      completion: status === "Completed" ? 100 : Math.floor(Math.random() * 100),
      location: {
        lat: 11 + Math.random() * 2, // Approximate latitude range for Jigawa
        lng: 8 + Math.random() * 2  // Approximate longitude range for Jigawa
      }
    };
  };
  
  export const projects = Array.from({ length: 100 }, (_, i) => generateRandomProject(i + 1));
  
  export { LGAs };