import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const statusColors = {
  'Completed': 'green',
  'Ongoing': 'orange',
  'Abandoned': 'red',
  'Planned': 'blue'
};

function ProjectMap({ projects }) {
  const center = [12.0, 9.7]; // Approximate center of Jigawa State

  return (
    <MapContainer center={center} zoom={8} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {projects.map((project) => (
        <Marker 
          key={project.id} 
          position={[project.location.lat, project.location.lng]}
          icon={new L.Icon({
            iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${statusColors[project.status] || 'blue'}.png`,
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          })}
        >
          <Popup>
            <div>
              <h3>{project.name}</h3>
              <p>Status: {project.status}</p>
              <p>LGA: {project.lga}</p>
              <p>Budget: ₦{project.budget.toLocaleString()}</p>
              <p>Completion: {project.completion}%</p>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default ProjectMap;