import React, { useState } from 'react';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import Statistics from './Statistics';
import ProjectMap from './ProjectMap';
import ProjectTable from './ProjectTable';
import SearchBar from './SearchBar';
import ProjectStatusChart from './ProjectStatusChart';
import { projects } from '../dummyData';
import logoImage from '../jglogo.png'; // Import the logo image

function Dashboard() {
  const [filteredProjects, setFilteredProjects] = useState(projects);

  const handleSearch = (searchTerm) => {
    const filtered = projects.filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.lga.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="lg" className="dashboard-container">
      {/* Logo and Title */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
      <img src={logoImage} alt="Jigawa State Logo" style={{ width: '200px', marginBottom: '40px' }} />
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          Jigawa State Infrastructure Dashboard
        </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} className="search-container">
            <SearchBar onSearch={handleSearch} />
          </Grid>
          <Grid item xs={12}>
            <Statistics projects={filteredProjects} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400 }} className="map-container">
              <ProjectMap projects={filteredProjects} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400 }}>
              <Typography variant="h6" gutterBottom>
                Project Status Distribution
              </Typography>
              <ProjectStatusChart projects={filteredProjects} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} className="table-container">
              <ProjectTable projects={filteredProjects} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Dashboard;