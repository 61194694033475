import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

function Statistics({ projects }) {
  const totalProjects = projects.length;
  const completed = projects.filter(p => p.status === 'Completed').length;
  const ongoing = projects.filter(p => p.status === 'Ongoing').length;
  const abandoned = projects.filter(p => p.status === 'Abandoned').length;
  const totalBudget = projects.reduce((sum, p) => sum + p.budget, 0);
  const averageCompletion = projects.reduce((sum, p) => sum + p.completion, 0) / totalProjects;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} md={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="stat-card">
          <Typography variant="h6">Total Projects</Typography>
          <Typography variant="h4">{totalProjects}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="stat-card">
          <Typography variant="h6">Completed</Typography>
          <Typography variant="h4">{completed}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="stat-card">
          <Typography variant="h6">Ongoing</Typography>
          <Typography variant="h4">{ongoing}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="stat-card">
          <Typography variant="h6">Abandoned</Typography>
          <Typography variant="h4">{abandoned}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="stat-card">
          <Typography variant="h6">Total Budget</Typography>
          <Typography variant="h6">₦{(totalBudget / 1e9).toFixed(2)}B</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="stat-card">
          <Typography variant="h6">Avg. Completion</Typography>
          <Typography variant="h6">{averageCompletion.toFixed(1)}%</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Statistics;