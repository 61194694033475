import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function ProjectTable({ projects }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="project table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>LGA</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Budget (₦)</TableCell>
            <TableCell align="right">Completion (%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow
              key={project.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {project.name}
              </TableCell>
              <TableCell>{project.lga}</TableCell>
              <TableCell>{project.type}</TableCell>
              <TableCell>{project.status}</TableCell>
              <TableCell align="right">{project.budget.toLocaleString()}</TableCell>
              <TableCell align="right">{project.completion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProjectTable;